import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "USYD's first cyber society!",
          "Where security meets innovation",
          "Your Security, Our Passion - DewaGuard",
          "Committed to continuous learning",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 100,
      }}
    />
  );
}

export default Type;

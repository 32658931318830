import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import designcompetition from "../../Assets/Projects/designcompetition.jpg";
import scavengerhunt from "../../Assets/Projects/scavengerhunt.jpg";
// Stuff from template
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";

import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiFillInstagram,

} from "react-icons/ai";

import { FaDiscord } from "react-icons/fa";


import { FaFacebookF } from "react-icons/fa";

import { FaLinkedinIn } from "react-icons/fa";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Upcoming<strong className="blue"> Events </strong>
        </h1>
        <p style={{ color: "white" }}>
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={scavengerhunt}
              isBlog={false}
              title="Scavenger Hunt (16 February 2024, 5:00 PM)"
              description="Embark on an exhilarating university-wide scavenger hunt that goes beyond the ordinary. Teams will face the exciting task of unraveling encryption puzzles, including ciphers, cleverly hidden at different spots across the campus. Success in each cryptographic challenge propels teams closer to the ultimate prize."
            />
          </Col>


        <h1 className="project-heading">
          Past<strong className="blue"> Events </strong>
        </h1>
        <p style={{ color: "white" }}>
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={designcompetition}
              isBlog={false}
              title="Exec & Merch Design Competition (December 2023)"
              description="Get ready to unleash your creativity in our Exec & Merch Design Competition! We are looking for talented designers to help us create our new logo and merchandise. The winning design will be used for our new logo and will be printed on our merchandise."
            />
          </Col>
          </Row>      

        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND US ON</h1>
            <p>
              Feel free to <span className="blue">connect </span>with us through any of these channels
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://discord.gg/gUGqmt4Gqf"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaDiscord />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/profile.php?id=61552628758945"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaFacebookF />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/company/usyd-csec"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/usyd_cybersoc/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
                </li>
            </ul>
          </Col>
        </Row>
 
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Particle from "../Particle";

function Contact() {
let t1 = "http://";
let currentDomain = window.location.host;
currentDomain = t1 + currentDomain + "/contact";
  return (
    <Container fluid className="contact-section">
      <Particle />
      <Container className="contact-container">
        <Row style={{ justifyContent: "center"}}>
          <Col md={7}>
            {/* Contact Description */}
            <h1 class="contact-heading">
              Get in Touch with <strong className="blue">Us</strong>
            </h1>
            <p class="contact-content">
              Whether you're interested in becoming a sponsor or joining our society, we're here to answer your questions. Fill out the form below, and we'll reach out to you shortly.
            </p>
          </Col>

          {/* Contact Form */}
          <Col md={8} style={{ paddingBottom: "30px" }}>
            <Form class="contact-form" 
	  action="https://formsubmit.co/27bb2c9f8df67e404849d5f60284aec2" 
	  method="POST">
	<input type="hidden" name="_captcha" value="true" />
	  <input type="hidden" name="_next" value={currentDomain} />
              {/* Sponsor Form */}
              <Form.Group controlId="sponsorForm">
                {/* <Form.Label>Your Name</Form.Label> */}
                <Form.Control type="text" placeholder="Your Name" name="name" />
                {/* <Form.Label>Your Email</Form.Label> */}
                <br />
                <Form.Control type="email" placeholder="Your Email" name="email" />
                {/* <Form.Label>Company Name</Form.Label> */}
                <br />
                <Form.Control type="text" placeholder="Company Name" name="c_name" />
                {/* <Form.Label>Your Message</Form.Label> */}
                <br />
                <Form.Control as="textarea" rows={3} placeholder="Your Message" name="message" />
                <br />
                <Button variant="primary" type="submit" value="send">
                  Submit
                </Button>
              </Form.Group>
            </Form>
        </Col>

          {/* Join Us Description */}
          <Col md={7}>
            {/* Contact Description */}
            <h1 class="contact-heading">
              Join <strong className="blue">Us</strong>
            </h1>
            <p class="contact-content">
            Fill in the form to embrace the warmth of our community. We are delighted to welcome you into out vibrant society. We will be in touch shortly!
            </p>
          </Col>

          {/* Join Society Form */}
          <Col md={8}>
            <Form class="contact-form" 
	  action="https://formsubmit.co/27bb2c9f8df67e404849d5f60284aec2" 
	  method="POST">
	  <input type="hidden" name="_captcha" value="true" />
          <input type="hidden" name="_next" value={currentDomain} />
              <Form.Group controlId="joinForm">
                {/* <Form.Label>Your Name</Form.Label> */}
                <Form.Control type="text" placeholder="Your Name" 
	  name = "name_join"/>
                <br />
                {/* <Form.Label>Your Email</Form.Label> */}
                <Form.Control type="email" placeholder="Your Email" 
	  name = "email_join" />
                <br />
                {/* <Form.Label>Why do you want to join?</Form.Label> */}
                <Form.Control as="textarea" rows={3} placeholder="Why do you want to join?" name = "msg_join" />
                <br />
                <Button variant="primary" type="submit" value = "send">
                  Join Us
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Contact;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/hackeranimation.png";
import Particle from "../Particle";
import Type from "./Type";
import myImg from "../../Assets/teampic.JPG";

import dewawebLogo from "../../Assets/dewaweb.png";
import dewaguardLogo from "../../Assets/dewaguard.png";

import Tilt from "react-parallax-tilt";
import { FaDiscord, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

function Home() {
    return (
        <section>
            <Container fluid className="home-section" id="home">
                <Particle />
                <Container className="home-content">
                    <Row className="home-row">
                        <Col md={7} className="home-header">
                            <h1 style={{ paddingBottom: 15 }} className="heading">
                                Welcome!{" "}
                                <span className="wave" role="img" aria-labelledby="wave">
                                    👋🏻
                                </span>
                            </h1>

                            <h1 className="heading-name">
                                Sydney University Cyber Security Society
                            </h1>

                            <div style={{ padding: "50px 50px 0 50px", textAlign: "left" }}>
                                <Type />
                            </div>
                        </Col>

                        <Col md={5} className="home-hacker-img-div">
                            <img className="home-hacker-img" src={homeLogo} alt="Hacker" />
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="home-about-section" id="about">
                    <Container>
                        <Row className="home-about-us">
                            <Col md={6} className="home-about-description">
                                <h1 style={{ fontSize: "2.6em" }}>
                                    ABOUT <span className="blue"> US! </span>
                                </h1>
                                <p className="home-about-body">
                                    <i>
                                        We are <b className="blue">Sydney University's first Cyber Security Society!</b>{" "}
                                        Our society is a dedicated community of individuals passionate about navigating the ever-evolving landscape of cybersecurity.
                                    </i>
                                    <br />
                                    <br />
                                    Incorporating engaging activities such as{" "}
                                    <i>
                                        <b className="blue">Capture the Flag competitions and industry workshops,</b>{" "}
                                    </i>
                                    our events are designed to address the growing imperative for cyber literacy in today's digital landscape.{" "}
                                    <b className="blue">Our ultimate goal is to empower our members </b>
                                    to emerge as exemplary individuals capable of navigating and excelling in the complex challenges of the cyber realm.
                                </p>
                            </Col>
                            <Col md={6} className="myAvtar">
                                <Tilt>
                                    <img src={myImg} className="img-fluid" alt="avatar" />
                                </Tilt>
                            </Col>
                        </Row>

                        <Row className="home-about-sponsors">
                            <Col md={8} className="home-about-description home-sponsors-description">
                                <h1 style={{ fontSize: "2.6em" }}>
                                    OUR <span className="blue"> SPONSORS! </span>
                                </h1>
                                <p className="home-about-body">
                                    <i>
                                        We are grateful for the support of our sponsors, who play a crucial role in fueling our mission to empower individuals in the field of cybersecurity.
                                    </i>
                                    <br />
                                    <br />
                                    Our sponsors, including industry leaders like{" "}
                                    <i>
                                        <b className="blue">Dewaweb and Dewaguard,</b>{" "}
                                    </i>
                                    contribute to the success of our events and initiatives, enabling us to provide valuable experiences and resources to our members.
                                    <br />
                                    <br />
                                    We believe in building strong partnerships with organizations that share our commitment to fostering a knowledgeable and skilled community in the cybersecurity domain.
                                </p>
                            </Col>

                            {/* Sponsor Logos */}
                            <Row className="sponsor-logos">
                                <a href="https://dewaweb.com" style={{width: "unset"}} target="_blank" rel="noopener noreferrer">
                                    <img src={dewawebLogo} className="sponsor-logo" alt="Dewaweb Logo" />
                                </a>
                                <a href="https://dewaguard.com" style={{width: "unset"}} target="_blank" rel="noopener noreferrer">
                                    <img src={dewaguardLogo} className="sponsor-logo" alt="Dewaguard Logo" />
                                </a>
                                {/* Add more sponsor logos as needed */}
                            </Row>
                        </Row>

                        <Row>
                            <Col md={12} className="home-about-social">
                                <h1>FIND US ON</h1>
                                <p>
                                    Feel free to <span className="blue">connect </span>with us through any of these channels
                                </p>
                                <ul className="home-about-social-links">
                                    <li className="social-icons">
                                        <a
                                            href="https://discord.gg/gUGqmt4Gqf"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="icon-colour home-social-icons"
                                        >
                                            <FaDiscord />
                                        </a>
                                    </li>
                                    <li className="social-icons">
                                        <a
                                            href="https://www.facebook.com/profile.php?id=61552628758945"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="icon-colour home-social-icons"
                                        >
                                            <FaFacebookF />
                                        </a>
                                    </li>
                                    <li className="social-icons">
                                        <a
                                            href="https://www.linkedin.com/company/usyd-csec"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="icon-colour home-social-icons"
                                        >
                                            <FaLinkedinIn />
                                        </a>
                                    </li>
                                    <li className="social-icons">
                                        <a
                                            href="https://www.instagram.com/usyd_cybersoc/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="icon-colour home-social-icons"
                                        >
                                            <AiFillInstagram />
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Container>
        </section>
    );
}

export default Home;
